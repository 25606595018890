class SupportMenu extends HTMLElement {
  constructor() {
    super();
    this.overlay = null;
    this.innerOverlay = null;
    this.mutationObserver = null;
  }

  connectedCallback() {
    this.overlay = document.querySelector('[data-header-overlay]');
    this.innerOverlay = this.overlay?.querySelector('.header-overlay__inner');

    const viewportWidth = parseFloat(this.getViewportWidth());

    if (viewportWidth >= 768) {
      this.handleDynamicMenu();
      this.setLeftPosition();
    }

    window.addEventListener(
      'resize',
      this.throttle(() => {
        this.setLeftPosition();
      }, 200),
      true
    );

    this.hideHeaderOnScroll();

    this.mutationObserver = new MutationObserver(() => {
      this.setLeftPosition();
    });
    this.mutationObserver.observe(this, { childList: true, subtree: true });
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.throttle);
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  getViewportWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  handleDynamicMenu() {
    this.addEventListener(
      'mouseenter',
      (event) => {
        if (
          event.target.matches('.js-parent-menu') &&
          window.innerWidth >= 768
        ) {
          this.closeAll();

          const heading = event.target.querySelector('.js-heading');
          const submenu = event.target.querySelector('.js-submenu');

          if (heading && submenu) {
            heading.classList.add('active');
            submenu.classList.add('active');
            this.overlay?.classList.add('is-active');
            this.innerOverlay?.classList.add('is-visible');
          }
        }
      },
      true
    );

    this.addEventListener(
      'mouseleave',
      (event) => {
        if (
          event.target.matches('.js-parent-menu') &&
          window.innerWidth >= 768
        ) {
          this.closeAll();
        }
      },
      true
    );
  }

  closeAll() {
    this.querySelectorAll('.js-parent-menu').forEach((parent) => {
      const heading = parent.querySelector('.js-heading');
      const submenu = parent.querySelector('.js-submenu');
      heading?.classList.remove('active');
      submenu?.classList.remove('active');
      this.overlay?.classList.remove('is-active');
      this.innerOverlay?.classList.remove('is-visible');
    });
  }

  setLeftPosition() {
    this.querySelectorAll('.js-parent-menu').forEach((parent) => {
      const submenu = parent.querySelector('.js-submenu');
      const leftPosition = submenu ? parent.getBoundingClientRect().left : 0;

      if (leftPosition > 0) {
        parent.style.setProperty(
          '--left-position',
          `${leftPosition.toFixed() - 1}px`
        );
      }
    });
  }

  throttle(func, limit) {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  hideHeaderOnScroll() {
    let lastScrollTop = 0;
    const delta = 50;
    const navbarHeight = this.offsetHeight;
    let ticking = false;

    const onScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          const st = window.pageYOffset || document.documentElement.scrollTop;

          if (Math.abs(lastScrollTop - st) <= delta) {
            ticking = false;
            return;
          }

          if (st > lastScrollTop && st > navbarHeight) {
            this.classList.add('nav-up');
          } else {
            if (
              st + window.innerHeight <
              document.documentElement.scrollHeight
            ) {
              this.classList.remove('nav-up');
            }
          }

          lastScrollTop = st;
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);
  }
}

window.customElements.define('support-menu', SupportMenu);
